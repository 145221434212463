<template>
  <div>
    <TitleComponent title-text="Custom Configs" />
    <div class="P-inputs">
      <div>
        <ott-textarea label="Configs" :rows="20" v-model="configs" />
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import OttTextarea from "../../../../components/vuetifyComponents/OttTextarea.vue";

export default {
  components: {
    TitleComponent,
    OttTextarea,
  },
  data() {
    return {
      configs: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.P-padding-100 {
  width: 100%;
  padding: 10px;
}
</style>
