<template>
  <div>
    <title-component title-text="General" />
    <div class="P-inputs G-flex flex-wrap">
      <div class="P-padding-100">
        <OttMultiselectDraggable
          :dragData="ipHostData"
          :enabled="true"
          :error="ipHostError"
          label="IP/Host*"
          @onInput="
            setDragVal({
              data: $event,
              keyName: 'ipHostData',
              errorKey: 'ipHostError',
              accessAddDrag: 'ipHostAddDrag',
            })
          "
          @onChange="
            ipHostAddDrag
              ? addDraggable({ data: ipHostData, keyName: 'ipHostData' })
              : () => null
          "
          @removeDraggable="
            removeDraggable({ dragId: $event, keyName: 'ipHostData' })
          "
        />
      </div>

      <div class="P-padding-100">
        <OttMultiselectDraggable
          :dragData="apiEnabledData"
          :enabled="true"
          :error="apiEnabledError"
          label="API Enabled From IP"
          @onInput="
            setDragVal({
              data: $event,
              keyName: 'apiEnabledData',
              errorKey: 'apiEnabledError',
              accessAddDrag: 'apiEnabledAddDrag',
            })
          "
          @onChange="
            apiEnabledAddDrag
              ? addDraggable({
                  data: apiEnabledData,
                  keyName: 'apiEnabledData',
                })
              : () => null
          "
          @removeDraggable="
            removeDraggable({ dragId: $event, keyName: 'apiEnabledData' })
          "
        />
      </div>

      <div class="P-padding-50">
        <ott-input
          v-model="adminLogin"
          :error="$v.formData.adminLogin.$error"
          label="Api Login*"
        />
      </div>

      <div class="P-padding-50">
        <password-input
          v-model="adminPass"
          :error="$v.formData.adminPass.$error"
          label="Admin Password*"
        />
      </div>

      <div class="P-padding-50">
        <ott-input v-model="pushPass" label="Push Pass" />
      </div>

      <div class="P-padding-50">
        <ott-input v-model="ownToken" label="Own Token" />
      </div>

      <div class="P-padding-50">
        <ott-input v-model="globalAuthUrl" label="Global Auth URL" />
      </div>

      <div class="P-padding-50">
        <ott-select
          v-model="logLevel"
          :items="logLevels"
          selected-text="label"
          label="Log Level"
        />
      </div>

      <div class="P-padding-33">
        <ott-switch v-model="nvidiaMonitor" label="Nvidia Monitor" />
      </div>

      <div class="P-padding-33">
        <ott-switch v-model="isEdge" label="Is Edge" />
      </div>

      <div class="P-padding-33">
        <ott-switch v-model="enabled" label="Enabled" />
      </div>

      <div class="P-padding-33">
        <ott-switch v-model="collectStat" label="Collect Stat" />
      </div>

      <div class="P-padding-33">
        <ott-switch v-model="collectMediaInfo" label="Collect Media Info" />
      </div>

      <div class="P-padding-33">
        <ott-switch v-model="splitSources" label="Split Sources" />
      </div>

      <div class="P-padding-33">
        <ott-switch v-model="remoteUpdate" label="Remote Update" />
      </div>
    </div>

    <ott-button-loader
      v-if="flussonicId"
      :is-loading="isLoading"
      @onClick="saveData"
      :button-text="flussonicId ? 'Save' : 'Add'"
      class-name="primary-sm-btn mt-2"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
// components
import OttMultiselectDraggable from "@/components/vuetifyComponents/OttMultiselectDraggable";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import PasswordInput from "@/components/PasswordInput";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import TitleComponent from "@/components/TitleComponent";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";

const validIpAddressRegex = new RegExp(
  "^(([1-9]?\\d|1\\d\\d|2[0-5][0-5]|2[0-4]\\d)\\.){3}([1-9]?\\d|1\\d\\d|2[0-5][0-5]|2[0-4]\\d)$"
);
const validHostnameRegex = new RegExp(
  "^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$"
);

export default {
  name: "General",
  validations: {
    formData: {
      adminLogin: { required },
      adminPass: { required },
    },
  },
  components: {
    OttMultiselectDraggable,
    TitleComponent,
    OttButtonLoader,
    OttInput,
    OttRadio,
    PasswordInput,
    OttSelect,
    OttSwitch,
  },
  data() {
    return {
      ipHostError: false,
      ipHostAddDrag: false,
      apiEnabledError: false,
      apiEnabledAddDrag: false,
      logLevels: [
        {
          value: 0,
          label: "Default",
        },
        {
          value: 1,
          label: "Debug",
        },
        {
          value: 2,
          label: "Notice",
        },
        {
          value: 3,
          label: "Error",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      ipHostData: (state) => state.generalFlussonic.ipHostData,
      apiEnabledData: (state) => state.generalFlussonic.apiEnabledData,
      formData: (state) => state.generalFlussonic.formData,
      flussonicId: (state) => state.flussonicsModule.flussonicId,
      isLoading: state => state.appModule.isLoading
    }),

    adminLogin: {
      get() {
        return this.formData.adminLogin;
      },
      set(adminLogin) {
        this.setDataSecondLevels({
          formData: { adminLogin },
        });
      },
    },
    adminPass: {
      get() {
        return this.formData.adminPass;
      },
      set(adminPass) {
        this.setDataSecondLevels({
          formData: { adminPass },
        });
      },
    },
    pushPass: {
      get() {
        return this.formData.pushPass;
      },
      set(pushPass) {
        this.setDataSecondLevels({
          formData: { pushPass },
        });
      },
    },
    ownToken: {
      get() {
        return this.formData.ownToken;
      },
      set(ownToken) {
        this.setDataSecondLevels({
          formData: { ownToken },
        });
      },
    },
    globalAuthUrl: {
      get() {
        return this.formData.globalAuthUrl;
      },
      set(globalAuthUrl) {
        this.setDataSecondLevels({
          formData: { globalAuthUrl },
        });
      },
    },
    logLevel: {
      get() {
        return this.formData.logLevel;
      },
      set(logLevel) {
        this.setDataSecondLevels({
          formData: { logLevel },
        });
      },
    },
    nvidiaMonitor: {
      get() {
        return this.formData.nvidiaMonitor;
      },
      set(value) {
        this.setDataSecondLevels({
          formData: { nvidiaMonitor: value ? true : false },
        });
      },
    },
    isEdge: {
      get() {
        return this.formData.isEdge;
      },
      set(value) {
        this.setDataSecondLevels({
          formData: { isEdge: value ? true : false },
        });
      },
    },
    enabled: {
      get() {
        return this.formData.enabled;
      },
      set(value) {
        this.setDataSecondLevels({
          formData: { enabled: value ? true : false },
        });
      },
    },
    collectStat: {
      get() {
        return this.formData.collectStat;
      },
      set(value) {
        this.setDataSecondLevels({
          formData: { collectStat: value ? true : false },
        });
      },
    },
    collectMediaInfo: {
      get() {
        return this.formData.collectMediaInfo;
      },
      set(value) {
        this.setDataSecondLevels({
          formData: { collectMediaInfo: value ? true : false },
        });
      },
    },
    splitSources: {
      get() {
        return this.formData.splitSources;
      },
      set(value) {
        this.setDataSecondLevels({
          formData: { splitSources: value ? true : false },
        });
      },
    },
    remoteUpdate: {
      get() {
        return this.formData.remoteUpdate;
      },
      set(value) {
        this.setDataSecondLevels({
          formData: { remoteUpdate: value ? true : false },
        });
      },
    },
  },
  methods: {
    ...mapMutations({
      setData: "generalFlussonic/setData",
      setDataSecondLevels: "generalFlussonic/setDataSecondLevels",
      setDragValue: "generalFlussonic/setDragValue",
      addDraggable: "generalFlussonic/addDraggable",
      removeDraggable: "generalFlussonic/removeDraggable",
    }),

    setDragVal({ data, keyName, errorKey, accessAddDrag }) {
      // IP/HostName validation, check if typed text match IP op HostName

      if (data.value) {
        if (
          data.value.trim().match(validIpAddressRegex) ||
          data.value.trim().match(validHostnameRegex)
        ) {
          this[errorKey] = false;
          this.setDragValue({ data, keyName });
          this[accessAddDrag] = true;
        } else {
          this[errorKey] = true;
          this[accessAddDrag] = false;
        }
      } else {
        this[errorKey] = false;
      }
    },

    async saveData() {
      this.$v.formData.$touch();
      if (!this.ipHostData.values.length) {
        this.ipHostError = true;
      }

      if (!this.apiEnabledData.values.length) {
        this.apiEnabledError = true;
      }

      if (!this.$v.formData.$error && !this.ipHostError && !this.apiEnabledError) {
        /*if (this.flussonicId) {
          await updateFunc()
        } else {
          await createFunc()
        }*/
        return Promise.resolve(true)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.P-inputs {
  margin: 0 -10px;
  &-block {
    width: 100%;
    padding: 0 10px;
  }
}

::v-deep {
  .P-loader-button {
    width: 100px;
  }

  .v-text-field__details,
  .v-messages {
    display: none;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-text-field {
    margin-top: 0;
  }

  .ma-2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .v-input__slot {
    margin-bottom: 0;
  }

  .elevation-6 {
    box-shadow: unset !important;
  }
}

.P-padding-100 {
  width: 100%;
  padding: 10px;
}

.P-padding-50 {
  width: 50%;
  padding: 10px;
}

.P-padding-33 {
  width: 33.33%;
  padding: 10px;
}

.P-label-text {
  font-size: $txt14;
  font-weight: $regular;
  color: $neutral-color;
  text-transform: capitalize;
  cursor: pointer;
}
</style>