var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title-component',{attrs:{"title-text":"Ports"}}),_c('div',{staticClass:"P-inputs G-flex flex-wrap"},[_c('div',{staticClass:"P-padding-100"},[_c('OttMultiselectDraggable',{attrs:{"dragData":_vm.HttpPortsData,"enabled":true,"error":_vm.HttpPortsError,"label":"HTTP Ports *"},on:{"onInput":function($event){return _vm.setDragValue({
            data: $event,
            keyName: 'HttpPortsData',
            error: 'HttpPortsError',
          })},"onChange":function($event){return _vm.addDraggable({ data: _vm.HttpPortsData, keyName: 'HttpPortsData' })},"removeDraggable":function($event){return _vm.removeDraggable({ dragId: $event, keyName: 'HttpPortsData' })}}})],1),_c('div',{staticClass:"P-padding-100"},[_c('OttMultiselectDraggable',{attrs:{"dragData":_vm.HttpsPortsData,"enabled":true,"error":_vm.HttpsPortsError,"label":"HTTPS Ports *"},on:{"onInput":function($event){return _vm.setDragValue({
            data: $event,
            keyName: 'HttpsPortsData',
            error: 'HttpsPortsError',
          })},"onChange":function($event){return _vm.addDraggable({ data: _vm.HttpsPortsData, keyName: 'HttpsPortsData' })},"removeDraggable":function($event){return _vm.removeDraggable({ dragId: $event, keyName: 'HttpsPortsData' })}}})],1),_c('div',{staticClass:"P-padding-100"},[_c('OttMultiselectDraggable',{attrs:{"dragData":_vm.adminHttpPortsData,"enabled":true,"error":_vm.adminHttpPortsError,"label":"Admin HTTP Ports"},on:{"onInput":function($event){return _vm.setDragValue({
            data: $event,
            keyName: 'adminHttpPortsData',
            error: 'adminHttpPortsError',
          })},"onChange":function($event){return _vm.addDraggable({
            data: _vm.adminHttpPortsData,
            keyName: 'adminHttpPortsData',
          })},"removeDraggable":function($event){return _vm.removeDraggable({ dragId: $event, keyName: 'adminHttpPortsData' })}}})],1),_c('div',{staticClass:"P-padding-100"},[_c('OttMultiselectDraggable',{attrs:{"dragData":_vm.adminHttpsPortsData,"enabled":true,"error":_vm.adminHttpsPortsError,"label":"Admin HTTPS Ports"},on:{"onInput":function($event){return _vm.setDragValue({
            data: $event,
            keyName: 'adminHttpsPortsData',
            error: 'adminHttpsPortsError',
          })},"onChange":function($event){return _vm.addDraggable({
            data: _vm.adminHttpsPortsData,
            keyName: 'adminHttpsPortsData',
          })},"removeDraggable":function($event){return _vm.removeDraggable({ dragId: $event, keyName: 'adminHttpsPortsData' })}}})],1),_c('div',{staticClass:"P-padding-100"},[_c('ott-input',{attrs:{"label":"RTMP Port","error":_vm.$v.rtmpPort.$error},model:{value:(_vm.$v.rtmpPort.$model),callback:function ($$v) {_vm.$set(_vm.$v.rtmpPort, "$model", $$v)},expression:"$v.rtmpPort.$model"}})],1),_c('div',{staticClass:"P-padding-100"},[_c('ott-input',{attrs:{"label":"RTSP Port","error":_vm.$v.rtspPort.$error},model:{value:(_vm.$v.rtspPort.$model),callback:function ($$v) {_vm.$set(_vm.$v.rtspPort, "$model", $$v)},expression:"$v.rtspPort.$model"}})],1),_c('div',{staticClass:"P-padding-100"},[_c('ott-input',{attrs:{"label":"SRT Port","error":_vm.$v.srtPort.$error},model:{value:(_vm.$v.srtPort.$model),callback:function ($$v) {_vm.$set(_vm.$v.srtPort, "$model", $$v)},expression:"$v.srtPort.$model"}})],1),_c('div',{staticClass:"P-padding-100"},[_c('ott-input',{attrs:{"label":"SRT Port","error":_vm.$v.msqlApiPort.$error},model:{value:(_vm.$v.msqlApiPort.$model),callback:function ($$v) {_vm.$set(_vm.$v.msqlApiPort, "$model", $$v)},expression:"$v.msqlApiPort.$model"}})],1),_c('div',{staticClass:"P-padding-100"},[_c('ott-input',{attrs:{"label":"SRT Port","error":_vm.$v.snmpPort.$error},model:{value:(_vm.$v.snmpPort.$model),callback:function ($$v) {_vm.$set(_vm.$v.snmpPort, "$model", $$v)},expression:"$v.snmpPort.$model"}})],1)]),(_vm.flussonicId)?_c('ott-button-loader',{attrs:{"is-loading":_vm.isLoading,"button-text":_vm.flussonicId ? 'Save' : 'Add',"class-name":"primary-sm-btn mt-2"},on:{"onClick":_vm.saveData}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }