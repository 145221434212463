<template>
  <div class="P-users-list">
    <div class="G-page-component" :class="{ 'P-filter-open': isOpenFilter }">
      <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title d-flex">
          <h3 class="secondary--text">Flussonics</h3>
        </div>
        <ott-button
          :click="openModal"
          class="primary-sm-btn"
          icon-before
          text="Add Flussonic"
          icon-before-class="mdi mdi-plus"
        />
      </div>
      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <ott-checkbox
              :indeterminate="selectNotAll"
              :dataVal="selectAllList"
              tooltip="Select all visibles"
              @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>
            <div
              v-if="selectedArrowList.length || selectedArrowListSub.length"
              class="approve-rej-icon-cont G-flex G-align-center"
            >
              <v-tooltip
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    @click="multipleDelete"
                    class="P-settings"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="mdi mdi-delete-outline neutral--text P-icon" />
                  </div>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </div>
          <div class="G-excel-btn">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="P-settings" v-bind="attrs" v-on="on">
                  <span class="mdi mdi-file-excel neutral--text P-icon" />
                </div>
              </template>
              <span>Download Excel</span>
            </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            :column-config="columnConfigData"
            :is-default="isDefault"
            @resetDataSettingsTable="resetDataTableSettings"
            @updateColumnsFromCheck="updateColumnsFromCheck"
            class="P-users-columns"
          />
        </div>
        <div>
          <div
            class="G-filter-btn"
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
              v-if="filterModel.filterCount.length"
              class="active-filter-count"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div
        class="G-component-body page-background"
      >
        <div class="G-sub-block-title">
          <h3 class="secondary--text">Flussonics List</h3>
        </div>
        <OttUniversalTable
          v-if="!loading"
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="flussonics.dataTable"
          :is-sub-table="false"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :main-data="flussonics.dataTable"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="false"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortFlussonicData"
          @updateColumnWith="updateColumn"
        >
          <template slot-scope="props" slot="action">
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in actionItems"
                  :key="index"
                  @click="selectAction($event, props, item.value)"
                >
                  <div>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </OttUniversalTable>
        <OttPaginationForTable
          v-model="filter.limit"
          v-if="flussonics.flussonicResult && !loading"
          :data-count="flussonics.dataCount"
          :available-data-count="flussonics.dataTable.length"
          :current-page="flussonics.currentPage"
          :page-limit="flussonics.totalPages"
          @update="updateLimit"
          @updatePage="updateFlussonics"
        />
        <NoDataInTable v-else />
      </div>
    </div>
    <div
      :class="{ 'P-filter-open': isOpenFilter }"
      class="G-filter-component page-background"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData />
      </div>
      <div class="G-filter-footer">
        <ott-button
          class="primary-lg-btn"
          text="Reset Filters"
          :click="resetData"
        >
        </ott-button>
      </div>
    </div>

    <PageLoader v-if="flussonics.isTableLoading || loading" />

    <ManageFlussonic v-if="isOpenManageFlussonicModal" />
    <WarningModal
      v-if="flussonics.showWarningModal"
      :isModalOpen="flussonics.showWarningModal"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="closeWarningModal"
      @btnFunction="warningModalBtnFunc"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
// mixins
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
import FilterMixin from "@/mixins/FilterMixin";
// components
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import NoDataInTable from "@/components/NoDataInTable";
import FilterData from "./components/FilterData";
import PageLoader from "../../components/pageLoader/PageLoader";
import ManageFlussonic from "./modals/ManageFlussonic";
// modals
import WarningModal from "@/components/WarningModal";

export default {
  name: "Flussonics",
  components: {
    ManageFlussonic,
    PageLoader,
    NoDataInTable,
    OttPaginationForTable,
    OttUniversalTable,
    OttHideShowColumnsOption,
    OttCheckbox,
    OttButton,
    FilterData,
    WarningModal,
  },
  mixins: [OttUniversalTableForComponentsMixin, FilterMixin],
  data() {
    return {
      defaultColumnConfigData: [],
      columnConfigData: [],
      actionItems: [
        { title: "Edit", value: "edit" },
        { title: "Delete", value: "delete" },
      ],
      multipleActions: [{ name: "Delete", value: "multipleDelete" }],
      flussonicId: null,
      warningModalType: "",
      warningModalBtnText: "",
      warningModalIsDangerButton: true,
      warningModalIsLoading: false,
      loading: false
    };
  },
  async created() {
    this.loading = true
    this.flussonicsTable();
    this.filterFlussonics();
    await this.getFlussonicsList(this.flussonics.filterData).finally(() => this.loading = false);
    this.resetSelectedList()
  },  
  beforeDestroy() {
    this.filterModel.reset()
    this.setData({ filterData: {} })
  },
  destroyed() {
    this.setFilterPage(1)
  },
  computed: {
    ...mapState({
      flussonics: (state) => state.flussonicsModule,
      dataTable: (state) => state.flussonicsModule.dataTable,
      isDefault: (state) => state.flussonicsModule.isDefault,
      filter: (state) => state.flussonicsModule.filter,
      filterModel: (state) => state.flussonicsModule.filterModel,
      isOpenManageFlussonicModal: (state) =>
        state.flussonicsModule.isOpenManageFlussonicModal,
    }),
    warningModalHeading() {
      return `${this.warningModalBtnText} Flussonic(s)?`;
    },

    warningModalText() {
      return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> one or more Flussonics! <br />  If you're not sure, you can cancel this operation.`;
    },
  },
  methods: {
    ...mapActions({
      getFlussonicsList: "flussonicsModule/getFlussonicsList",
      getFlussonicData: "flussonicsModule/getFlussonicData",
      flussonicDelete: "flussonicsModule/flussonicDelete",
    }),
    ...mapMutations({
      setData: "flussonicsModule/setData",
      setFilterPage: "flussonicsModule/setFilterPage",
      sortColumn: "flussonicsModule/sortColumn",
      filterFlussonics: "flussonicsModule/filterFlussonics"
    }),
    flussonicsTable() {
      this.defaultColumnConfigData = [
        {
          title: "ID",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "number",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 0,
          mainIndex: 0,
          sortData: {
            isSort: true,
            sortKey: "number",
            sortValue: "",
          },
          cellView: (row) => `<p class="G-text-table">${row.number}</p>`,
        },
        {
          title: "Host Name",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "hostName",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 1,
          mainIndex: 1,
          sortData: {
            isSort: true,
            sortKey: "hostName",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.hostName !== undefined
                ? row.hostName.length
                  ? row.name.map((name) =>
                      name.lang === "en" ? name.name : row.name[0].name
                    )[0]
                  : "-"
                : "-"
            } </p>`,
        },
        {
          title: "IP/Host",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "ipHost",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 2,
          mainIndex: 2,
          cellView: (row) =>
            row.ipHost !== undefined
              ? row.ipHost
                  .map(
                    (item) =>
                      `<p class="G-text-table G-sub-column">${item.name}</p>`
                  )
                  .join("")
              : "-",
        },
        {
          title: "API enabled IP",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "apiEnabledIp",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 3,
          mainIndex: 3,
          cellView: (row) =>
            row.apiEnabled !== undefined
              ? row.apiEnabled
                  .map(
                    (item) =>
                      `<p class="G-text-table G-sub-column">${item.name}</p>`
                  )
                  .join("")
              : "-",
        },
        {
          title: "DVR depth",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "dvrDepth",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 4,
          mainIndex: 4,
          sortData: {
            isSort: true,
            sortKey: "dvrDepth",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.dvrDepth !== undefined ? row.dvrDepth : "-"
            }</p>`,
        },
        {
          title: "Enabled",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "enabled",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 5,
          mainIndex: 5,
          sortData: {
            isSort: true,
            sortKey: "enabled",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.enabled !== undefined ? row.enabled : "-"
            }</p>`,
        },
        {
          title: "Collect Stat",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "collectStat",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 6,
          mainIndex: 6,
          sortData: {
            isSort: true,
            sortKey: "collectStat",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.collectStat !== undefined ? row.collectStat : "-"
            }</p>`,
        },
        {
          title: "Remote Update",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "remoteUpdate",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 7,
          mainIndex: 7,
          sortData: {
            isSort: true,
            sortKey: "remoteUpdate",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.remoteUpdate !== undefined ? row.remoteUpdate : "-"
            }</p>`,
        },
        {
          title: "Get Channels Mediainfo",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "channelsMediainfo",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 8,
          mainIndex: 8,
          sortData: {
            isSort: true,
            sortKey: "channelsMediainfo",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.channelsMediainfo !== undefined ? row.channelsMediainfo : "-"
            }</p>`,
        },
        {
          title: "Split Sources",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "splitSources",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 9,
          mainIndex: 9,
          sortData: {
            isSort: true,
            sortKey: "splitSources",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.splitSources !== undefined ? row.splitSources : "-"
            }</p>`,
        },
        {
          title: "Is Edge",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "isEdge",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 10,
          mainIndex: 10,
          sortData: {
            isSort: true,
            sortKey: "isEdge",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.isEdge !== undefined ? row.isEdge : "-"
            }</p>`,
        },
        {
          title: "Api Version",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "apiVersion",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 11,
          mainIndex: 11,
          sortData: {
            isSort: true,
            sortKey: "apiVersion",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.apiVersion !== undefined ? row.apiVersion : "-"
            }</p>`,
        },
        {
          title: "Flussonic Version",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "flussonicVersion",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 12,
          mainIndex: 12,
          sortData: {
            isSort: true,
            sortKey: "flussonicVersion",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.flussonicVersion !== undefined ? row.flussonicVersion : "-"
            }</p>`,
        },
        {
          title: "Log Level",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "logLevel",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 13,
          mainIndex: 13,
          cellView: (row) =>
            `<p class="G-text-table">${
              row.logLevel !== undefined ? row.logLevel : "-"
            }</p>`,
        },
        {
          title: "Host Online Status",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "hostOnlineStatus",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 14,
          mainIndex: 14,
          sortData: {
            isSort: true,
            sortKey: "hostOnlineStatus",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row.hostOnlineStatus !== undefined ? row.hostOnlineStatus : "-"
            }</p>`,
        },
        {
          title: "Flussonic Online Status",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "flussonicOnlineStatus",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 15,
          mainIndex: 15,
          cellView: (row) =>
            `<p class="G-text-table">${
              row.flussonicOnlineStatus !== undefined
                ? row.flussonicOnlineStatus
                : "-"
            }</p>`,
        },
      ];
      this.columnConfigData = [];
      this.columnConfigData = this.OttClone(this.defaultColumnConfigData);
    },

    openModal() {
      this.setData({ isOpenManageFlussonicModal: true });
    },

    resetDataTableSettings() {},

    async resetData() {
      this.filterModel.reset();
      await this.getFlussonicsList(this.flussonics.filterData);
      this.resetSelectedList()
    },

    async sortFlussonicData(data) {
      this.sortColumn(data);
      await this.getFlussonicsList(this.flussonics.filterData);
    },

    async updateLimit() {
      this.sortColumn()
      await this.getFlussonicsList(this.flussonics.filterData);
      this.resetSelectedList()
    },

    async updateFlussonics(page) {
      if (page) {
        this.setFilterPage(page);
      }
      this.sortColumn()
      await this.getFlussonicsList(this.flussonics.filterData);
      this.resetSelectedList()
    },

    async selectAction(event, data, action) {
      let flussonicID = data.rowData.id;
      this.setData({ flussonicId });
      switch (action) {
        case "edit":
          {
            await this.getFlussonicData(flussonicID);
            this.setData({ isOpenManageFlussonicModal: true });
          }
          break;
        case "delete":
          {
            this.warningModalBtnText = "Delete";
            this.warningModalType = "delete";
            this.warningModalIsDangerButton = true;
            this.setData({ showWarningModal: true });
            this.flussonicId = flussonicID;
          }
          break;
      }
    },

    async warningModalBtnFunc() {
      await this.warningModalBtnFunction().finally(() => {
        this.closeWarningModal();
      });
    },

    async warningModalBtnFunction() {
      this.warningModalIsLoading = true;
      switch (this.warningModalType) {
        case "delete":
        case "multipleDelete":
          await this.deleteFlussonic();
          break;
      }
    },

    multipleDelete() {
      this.warningModalBtnText = "Delete";
      this.warningModalType = "delete";
      this.warningModalIsDangerButton = true;
      this.setData({ showWarningModal: true });
    },

    async deleteFlussonic() {
      if (this.flussonicId) {
        await this.equipmentTypeDelete({
          equipmentTypeId: [this.flussonicId],
        });
      } else if (this.selectedArrowList.length) {
        await this.equipmentTypeDelete({
          equipmentTypeId: [...this.selectedArrowList.map((item) => item.id)],
        });
        this.resetSelectedList();
      }
    },

    closeWarningModal() {
      this.warningModalIsLoading = false;
      this.setData({ showWarningModal: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables.scss";

.P-users-list {
  position: relative;
}

.P-users-columns {
  padding-left: 10px;
}

.v-menu__content {
  max-width: 190px !important;
}
.approve-rej-icon-cont {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1.5px solid #a5aac6;
}
</style>
