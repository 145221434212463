 <template>
  <div>
    <title-component title-text="DVR" />
    <div class="P-inputs G-flex flex-wrap">
      <div class="P-padding-100 pt-0">
        <ott-switch v-model="dvrEnable" label="DVR Enable" />
      </div>

      <div v-if="dvrEnable" class="P-padding-50">
        <ott-input
          v-model="dvrPath"
          :error="$v.dvrFormData.dvrPath.$error"
          label="DVR Path"
        />
      </div>

      <div v-if="dvrEnable" class="P-padding-50">
        <ott-input
          v-model="dvrDays"
          :error="$v.dvrFormData.dvrDays.$error"
          label="DVR Days"
          type="number"
        />
      </div>

      <div v-if="dvrEnable" class="P-padding-50">
        <ott-input
          v-model="dvrHours"
          :error="$v.dvrFormData.dvrHours.$error"
          label="DVR Hours"
          type="number"
        />
      </div>

      <div v-if="dvrEnable" class="P-padding-50">
        <ott-input
          v-model="dvrDiskSpaceLimit"
          :error="$v.dvrFormData.dvrDiskSpaceLimit.$error"
          label="DVR Disk Space Limit (%)"
          type="number"
        />
      </div>

      <div class="P-padding-50">
        <ott-input v-model="dvrCachePath" label="DVR Cache Path" />
      </div>

      <div class="P-padding-50">
        <ott-input
          v-model="dvrCacheDays"
          type="number"
          label="DVR Cache Days"
        />
      </div>

      <div class="P-padding-50">
        <ott-input
          v-model="dvrСacheHours"
          type="number"
          label="DVR Cache Hours"
        />
      </div>

      <div class="P-padding-50">
        <ott-input
          v-model="dvrCacheSpaceLimit"
          type="number"
          label="DVR Cache Space Limit (GB)"
        />
      </div>
    </div>

    <div class="mt-2">
      <title-component
        :click="() => (showVodInputs = true)"
        title-text="VOD"
        is-add
      />
    </div>

    <div v-if="showVodInputs" class="P-inputs G-flex flex-wrap">
      <div class="P-padding-50">
        <ott-input
          v-model="vodPrefix"
          :error="$v.vodFormData.vodPrefix.$error"
          type="text"
          label="VOD Prefix"
        />
      </div>

      <div class="P-padding-50">
        <ott-input v-model="vodCachePath" type="text" label="VOD Cache Path" />
      </div>

      <div class="P-padding-100">
        <OttMultiselectDraggable
          :dragData="vodPaths"
          :enabled="true"
          :error="vodPathsError"
          label="VOD Paths"
          @onInput="
            setDragVal({
              data: $event,
              keyName: 'vodPaths',
              errorKey: 'vodPathsError',
              accessAddDrag: 'vodPathsAddDrag',
            })
          "
          @onChange="
            vodPathsAddDrag
              ? addDraggable({
                  data: vodPaths,
                  keyName: 'vodPaths',
                })
              : () => null
          "
          @removeDraggable="
            removeDraggable({ dragId: $event, keyName: 'vodPaths' })
          "
        />
      </div>

      <div class="P-padding-50">
        <ott-input
          v-model="vodCacheDays"
          :min="0"
          type="number"
          label="VOD Cache Days"
        />
      </div>

      <div class="P-padding-50">
        <ott-input
          v-model="vodCacheHours"
          :min="0"
          type="number"
          label="VOD Cache Hours"
        />
      </div>

      <div class="P-padding-50">
        <ott-input
          v-model="vodCacheSpaceLimit"
          :min="0"
          type="number"
          label="VOD Cache Space Limit"
        />
      </div>

      <div class="P-padding-50">
        <ott-input
          v-model="vodCacheMisses"
          :min="0"
          type="number"
          label="VOD Cache Misses"
        />
      </div>

      <div class="P-padding-100">
        <ott-input
          v-model="vodOtherConfigs"
          :min="0"
          type="test"
          label="VOD Other Configs"
        />
      </div>
      <SaveResetButtons
        left-label="Cancel"
        :right-label="!isAddVod ? 'Save' : 'Add'"
        :right-click-func="isAddVod ? editVode : addVodeData"
        :left-click-func="vodFormCancel"
      />
      <!-- v-if="(isAddVod || !isAddVod) && vodList.length" -->
      <!-- :is-loading="" -->
    </div>

    <div v-if="!showVodInputs && vodList.length" class="P-table-block vod-list">
      <div class="P-table-header">
        <ul class="G-flex">
          <li
            class="secondary--text justify-center"
            v-for="(item, key) of vodListHeaders"
            :key="key"
          >
            {{ item }}
          </li>
          <li></li>
        </ul>
      </div>
      <div class="P-table-body">
        <ul class="G-flex" v-for="(item, index) of vodList" :key="index">
          <li d-block>{{ item.vodPrefix }}</li>
          <li class="align-start">
            <span
              class="mb-2"
              v-for="(vodPath, index) of item.vodPaths"
              :key="index"
            >
              <i>{{ vodPath.name }}, &nbsp;</i>
            </span>
          </li>
          <li>{{ item.vodCachePath }}</li>
          <li>{{ item.vodCacheDays }}</li>
          <li class="G-align-center">
            <div class="P-actions">
              <v-menu left content-class="P-actions-list">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(action, key) of vodActions" @click="action.clckFunction" :key="key" class="P-list">
                    <v-list-item-title>{{ action.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <ott-button-loader
      v-if="flussonicId"
      :is-loading="isLoading"
      @onClick="saveData"
      :button-text="flussonicId ? 'Save' : 'Add'"
      class-name="primary-sm-btn mt-2"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
// components
import OttMultiselectDraggable from "@/components/vuetifyComponents/OttMultiselectDraggable";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import TitleComponent from "@/components/TitleComponent";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";

export default {
  name: "DvrVod",
  components: {
    OttMultiselectDraggable,
    TitleComponent,
    OttButtonLoader,
    OttInput,
    OttSwitch,
  },
  validations: {
    dvrFormData: {
      dvrPath: {
        required: requiredIf(function () {
          return this.dvrEnable;
        }),
      },
      dvrDays: {
        required: requiredIf(function () {
          return this.dvrEnable;
        }),
      },
      dvrHours: {
        required: requiredIf(function () {
          return this.dvrEnable;
        }),
      },
      dvrDiskSpaceLimit: {
        required: requiredIf(function () {
          return this.dvrEnable;
        }),
      },
    },

    vodFormData: {
      vodPrefix: { required },
    },
  },

  data() {
    return {
      dvrEnable: false,
      vodPathsError: false,
      vodPathsAddDrag: false,
      showVodInputs: false,
      vodListHeaders: [
        "VOD Prefix",
        "VOD Paths",
        "VOD Cache Path",
        "VOD Cache Days",
      ],
      vodActions: [
        {
          title: "Edit",
          clckFunction: this.editVodfunc
        },
        {
          title: "Delete",
          clckFunction: this.deleteVodFunc
        }
      ]
    };
  },
  computed: {
    ...mapState({
      dvrFormData: (state) => state.dvrVodFlussonic.dvrFormData,
      vodFormData: (state) => state.dvrVodFlussonic.vodFormData,
      flussonicId: (state) => state.flussonicsModule.flussonicId,
      isAddBtnLoading: (state) => state.dvrVodFlussonic.isAddBtnLoading,
      vodPaths: (state) => state.dvrVodFlussonic.vodPaths,
      isAddVod: (state) => state.dvrVodFlussonic.isAddVod,
      vodList: (state) => state.dvrVodFlussonic.vodList,
      isLoading: (state) => state.appModule.isLoading
    }),

    dvrPath: {
      get() {
        return this.dvrFormData.dvrPath;
      },
      set(dvrPath) {
        this.setDataSecondLevels({
          dvrFormData: { dvrPath },
        });
      },
    },
    dvrDays: {
      get() {
        return this.dvrFormData.dvrDays;
      },
      set(dvrDays) {
        this.setDataSecondLevels({
          dvrFormData: { dvrDays },
        });
      },
    },
    dvrHours: {
      get() {
        return this.dvrFormData.dvrHours;
      },
      set(dvrHours) {
        this.setDataSecondLevels({
          dvrFormData: { dvrHours },
        });
      },
    },
    dvrDiskSpaceLimit: {
      get() {
        return this.dvrFormData.dvrDiskSpaceLimit;
      },
      set(dvrDiskSpaceLimit) {
        this.setDataSecondLevels({
          dvrFormData: { dvrDiskSpaceLimit },
        });
      },
    },
    dvrCachePath: {
      get() {
        return this.dvrFormData.dvrCachePath;
      },
      set(dvrCachePath) {
        this.setDataSecondLevels({
          dvrFormData: { dvrCachePath },
        });
      },
    },
    dvrCacheDays: {
      get() {
        return this.dvrFormData.dvrCacheDays;
      },
      set(dvrCacheDays) {
        this.setDataSecondLevels({
          dvrFormData: { dvrCacheDays },
        });
      },
    },
    dvrСacheHours: {
      get() {
        return this.dvrFormData.dvrСacheHours;
      },
      set(dvrСacheHours) {
        this.setDataSecondLevels({
          dvrFormData: { dvrСacheHours },
        });
      },
    },
    dvrCacheSpaceLimit: {
      get() {
        return this.dvrFormData.dvrCacheSpaceLimi;
      },
      set(dvrCacheSpaceLimi) {
        this.setDataSecondLevels({
          dvrFormData: { dvrCacheSpaceLimi },
        });
      },
    },

    vodPrefix: {
      get() {
        return this.vodFormData.vodPrefix;
      },
      set(vodPrefix) {
        this.setDataSecondLevels({
          vodFormData: { vodPrefix },
        });
      },
    },
    vodCachePath: {
      get() {
        return this.vodFormData.vodCachePath;
      },
      set(vodCachePath) {
        this.setDataSecondLevels({
          vodFormData: { vodCachePath },
        });
      },
    },
    vodCacheDays: {
      get() {
        return this.vodFormData.vodCacheDays;
      },
      set(vodCacheDays) {
        this.setDataSecondLevels({
          vodFormData: { vodCacheDays },
        });
      },
    },
    vodCacheHours: {
      get() {
        return this.vodFormData.vodCacheHours;
      },
      set(vodCacheHours) {
        this.setDataSecondLevels({
          vodFormData: { vodCacheHours },
        });
      },
    },
    vodCacheSpaceLimit: {
      get() {
        return this.vodFormData.vodCacheSpaceLimit;
      },
      set(vodCacheSpaceLimit) {
        this.setDataSecondLevels({
          vodFormData: { vodCacheSpaceLimit },
        });
      },
    },
    vodCacheMisses: {
      get() {
        return this.vodFormData.vodCacheMisses;
      },
      set(vodCacheMisses) {
        this.setDataSecondLevels({
          vodFormData: { vodCacheMisses },
        });
      },
    },
    vodOtherConfigs: {
      get() {
        return this.vodFormData.vodOtherConfigs;
      },
      set(vodOtherConfigs) {
        this.setDataSecondLevels({
          vodFormData: { vodOtherConfigs },
        });
      },
    },
  },
  methods: {
    ...mapMutations({
      setData: "dvrVodFlussonic/setData",
      setDataSecondLevels: "dvrVodFlussonic/setDataSecondLevels",
      setDragValue: "dvrVodFlussonic/setDragValue",
      addDraggable: "dvrVodFlussonic/addDraggable",
      addVodMutation: "dvrVodFlussonic/addVod",
      removeDraggable: "dvrVodFlussonic/removeDraggable",
      resetVodFormData: "dvrVodFlussonic/resetVodFormData",
    }),

    setDragVal({ data, keyName, errorKey, accessAddDrag }) {
      if (data.value) {
        this[errorKey] = false;
        this.setDragValue({ data, keyName });
        this[accessAddDrag] = true;
      } else {
        this[errorKey] = false;
      }
    },

    addVodeData() {
      this.vodFormValidation();

      if (!this.$v.vodFormData.$error && this.vodPaths.values.length) {
        this.addVodMutation();
        this.showVodInputs = false;
        this.vodPathsError = false;

        this.resetVodFormData();
        this.$v.vodFormData.$reset();
      }
    },

    editVode() {
      return "editVode";
    },

    saveData() {
      this.formsValidation();

      if (!this.showVodInputs) {
        if (!this.$v.dvrFormData.$error) {
          // Save DVR
        }
      }

      if (this.showVodInputs) {
        if (
          !this.$v.dvrFormData.$error &&
          !this.$v.vodFormData.$error &&
          !this.vodPaths.values.length
        ) {
          // Save DVR and VOD
        }
      }
    },

    formsValidation() {
      this.$v.dvrFormData.$touch();

      if (this.showVodInputs) {
        this.vodFormValidation();
      }
    },

    vodFormValidation() {
      this.$v.vodFormData.$touch();

      if (!this.vodPaths.values.length) {
        this.vodPathsError = true;
      }
    },

    vodFormCancel() {
      this.showVodInputs = false;
      this.vodPathsError = false;
      this.resetVodFormData();
    },

    editVodfunc() {
      this.showVodInputs = true;
    },
    deleteVodFunc() {},
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "src/views/clients/modals/manageClient/simpleTable";

::v-deep {
  .P-loader-button {
    width: 100px;
  }

  .v-text-field__details,
  .v-messages {
    display: none;
  }

  .elevation-6 {
    box-shadow: unset !important;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }
}

.P-inputs {
  margin: 0 -10px;
}
.P-padding-100 {
  width: 100%;
  padding: 10px;
}

.P-padding-50 {
  width: 50%;
  padding: 10px;
}

.P-padding-33 {
  width: 33.33%;
  padding: 10px;
}

// .vod-list {
//   ul {
//     li {
//       border-top: unset !important;
//       &:nth-child(1),
//       &:nth-child(2),
//       &:nth-child(3),
//       &:nth-child(4) {
//         max-width: 150px;
//         min-width: 150px;
//       }
//     }
//   }
// }

// .P-table-body {
//   border-top: 1px solid #a5aac6;
// }
</style>
