<template>
  <div>
    <title-component title-text="Ports" />
    <div class="P-inputs G-flex flex-wrap">
      <div class="P-padding-100">
        <OttMultiselectDraggable
          :dragData="HttpPortsData"
          :enabled="true"
          :error="HttpPortsError"
          label="HTTP Ports *"
          @onInput="
            setDragValue({
              data: $event,
              keyName: 'HttpPortsData',
              error: 'HttpPortsError',
            })
          "
          @onChange="
            addDraggable({ data: HttpPortsData, keyName: 'HttpPortsData' })
          "
          @removeDraggable="
            removeDraggable({ dragId: $event, keyName: 'HttpPortsData' })
          "
        />
      </div>
      <div class="P-padding-100">
        <OttMultiselectDraggable
          :dragData="HttpsPortsData"
          :enabled="true"
          :error="HttpsPortsError"
          label="HTTPS Ports *"
          @onInput="
            setDragValue({
              data: $event,
              keyName: 'HttpsPortsData',
              error: 'HttpsPortsError',
            })
          "
          @onChange="
            addDraggable({ data: HttpsPortsData, keyName: 'HttpsPortsData' })
          "
          @removeDraggable="
            removeDraggable({ dragId: $event, keyName: 'HttpsPortsData' })
          "
        />
      </div>
      <div class="P-padding-100">
        <OttMultiselectDraggable
          :dragData="adminHttpPortsData"
          :enabled="true"
          :error="adminHttpPortsError"
          label="Admin HTTP Ports"
          @onInput="
            setDragValue({
              data: $event,
              keyName: 'adminHttpPortsData',
              error: 'adminHttpPortsError',
            })
          "
          @onChange="
            addDraggable({
              data: adminHttpPortsData,
              keyName: 'adminHttpPortsData',
            })
          "
          @removeDraggable="
            removeDraggable({ dragId: $event, keyName: 'adminHttpPortsData' })
          "
        />
      </div>
      <div class="P-padding-100">
        <OttMultiselectDraggable
          :dragData="adminHttpsPortsData"
          :enabled="true"
          :error="adminHttpsPortsError"
          label="Admin HTTPS Ports"
          @onInput="
            setDragValue({
              data: $event,
              keyName: 'adminHttpsPortsData',
              error: 'adminHttpsPortsError',
            })
          "
          @onChange="
            addDraggable({
              data: adminHttpsPortsData,
              keyName: 'adminHttpsPortsData',
            })
          "
          @removeDraggable="
            removeDraggable({ dragId: $event, keyName: 'adminHttpsPortsData' })
          "
        />
      </div>
      <div class="P-padding-100">
        <ott-input
          v-model="$v.rtmpPort.$model"
          label="RTMP Port"
          :error="$v.rtmpPort.$error"
        />
      </div>
      <div class="P-padding-100">
        <ott-input
          v-model="$v.rtspPort.$model"
          label="RTSP Port"
          :error="$v.rtspPort.$error"
        />
      </div>
      <div class="P-padding-100">
        <ott-input
          v-model="$v.srtPort.$model"
          label="SRT Port"
          :error="$v.srtPort.$error"
        />
      </div>
      <div class="P-padding-100">
        <ott-input
          v-model="$v.msqlApiPort.$model"
          label="SRT Port"
          :error="$v.msqlApiPort.$error"
        />
      </div>
      <div class="P-padding-100">
        <ott-input
          v-model="$v.snmpPort.$model"
          label="SRT Port"
          :error="$v.snmpPort.$error"
        />
      </div>
    </div>
    <ott-button-loader
      v-if="flussonicId"
      :is-loading="isLoading"
      @onClick="saveData"
      :button-text="flussonicId ? 'Save' : 'Add'"
      class-name="primary-sm-btn mt-2"
    />
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import OttMultiselectDraggable from "@/components/vuetifyComponents/OttMultiselectDraggable";
import { required, between } from "vuelidate/lib/validators";
import { mapState, mapMutations } from "vuex";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttInput from "../../../../components/vuetifyComponents/OttInput.vue";

const tcpIpRegex = new RegExp(
  "^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$"
);

export default {
  components: {
    TitleComponent,
    OttMultiselectDraggable,
    OttButtonLoader,
    OttInput,
  },
  data() {
    return {
      HttpPortsError: false,
      HttpsPortsError: false,
      adminHttpPortsError: false,
      adminHttpsPortsError: false,
    };
  },
  validations: {
    rtmpPort: {
      between: between(1, 65635),
    },
    rtspPort: {
      between: between(1, 65635),
    },
    srtPort: {
      between: between(1, 65635),
    },
    msqlApiPort: {
      between: between(1, 65635),
    },
    snmpPort: {
      between: between(1, 65635),
    },
  },
  computed: {
    ...mapState({
      flussonicId: (state) => state.flussonicsModule.flussonicId,
      HttpPortsData: (state) => state.portsFlussonic.HttpPortsData,
      HttpsPortsData: (state) => state.portsFlussonic.HttpsPortsData,
      adminHttpPortsData: (state) => state.portsFlussonic.adminHttpPortsData,
      adminHttpsPortsData: (state) => state.portsFlussonic.adminHttpsPortsData,
      formData: (state) => state.portsFlussonic.formData,
      isLoading: state => state.appModule.isLoading
    }),

    rtmpPort: {
      get() {
        return this.formData.rtmpPort;
      },
      set(rtmpPort) {
        this.setDataSecondLevels({
          formData: { rtmpPort },
        });
      },
    },
    rtspPort: {
      get() {
        return this.formData.rtspPort;
      },
      set(rtspPort) {
        this.setDataSecondLevels({
          formData: { rtspPort },
        });
      },
    },
    srtPort: {
      get() {
        return this.formData.srtPort;
      },
      set(srtPort) {
        this.setDataSecondLevels({
          formData: { srtPort },
        });
      },
    },
    msqlApiPort: {
      get() {
        return this.formData.msqlApiPort;
      },
      set(msqlApiPort) {
        this.setDataSecondLevels({
          formData: { msqlApiPort },
        });
      },
    },
    snmpPort: {
      get() {
        return this.formData.snmpPort;
      },
      set(snmpPort) {
        this.setDataSecondLevels({
          formData: { snmpPort },
        });
      },
    },
  },
  methods: {
    ...mapMutations({
      setData: "portsFlussonic/setData",
      setDataSecondLevels: "portsFlussonic/setDataSecondLevels",
      setValue: "portsFlussonic/setValue",
      addDraggable: "portsFlussonic/addDraggable",
      removeDraggable: "portsFlussonic/removeDraggable",
    }),
    checkIsValidInteger(data) {
      if (data.match(tcpIpRegex)) {
        return true;
      }
      return false;
    },

    setDragValue({ data, keyName, error }) {
      if (this.checkIsValidInteger(data.value) || data.value === "") {
        this[error] = false;
        this.setValue({ data, keyName });
      } else {
        this[error] = true;
      }
    },

    validateSaveData(data, error) {
      if (data) {
        this[error] = false;
        return true;
      } else {
        this[error] = true;
      }
      return false;
    },

    async saveData() {
      this.$v.$touch()
      this.validateSaveData(this.HttpPortsData.values.length, "HttpPortsError");
      this.validateSaveData(
        this.HttpsPortsData.values.length,
        "HttpsPortsError"
      );
      if (!this.HttpPortsError && !this.HttpsPortsError && !this.$v.$invalid) {

        if (this.equipmentTypeId) {
          // await updateFunc()
        } else {
          // await createFunc()
        }
        return Promise.resolve(true)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.P-inputs {
  margin: 0 -10px;
  max-height: 560px;
  overflow-y: auto;
  &-block {
    width: 100%;
    padding: 0 10px;
  }
}

::v-deep {
  .P-loader-button {
    width: 100px;
  }

  .v-text-field__details,
  .v-messages {
    display: none;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-text-field {
    margin-top: 0;
  }

  .ma-2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .v-input__slot {
    margin-bottom: 0;
  }

  .elevation-6 {
    box-shadow: unset !important;
  }
}

.P-padding-50 {
  width: 50%;
  padding: 10px;
}

.P-padding-100 {
  width: 100%;
  padding: 10px;
}
</style>