<template>
  <div class="P-filters">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify neutral--text"
      />
    </div>
    <div class="P-input-block">
      <ott-select-autocomplete
        v-model="filterModel.enabled"
        @emitFunc="filterData('enabled')"
        :height="38"
        :items="enableList"
        :clear="true"
        outlined
        selected-text="name"
        label="Enabled"
      />
    </div>
    <div class="P-input-block">
      <ott-select-autocomplete
        v-model="filterModel.collectStat"
        @emitFunc="filterData('collectStat')"
        :height="38"
        :items="collectStatList"
        :clear="true"
        outlined
        selected-text="name"
        label="Collect Stat"
      />
    </div>
    <div class="P-input-block">
      <ott-select-autocomplete
        v-model="filterModel.remoteUpdate"
        @emitFunc="filterData('remoteUpdate')"
        :height="38"
        :items="remoteUpdateList"
        :clear="true"
        outlined
        selected-text="name"
        label="Remote Update"
      />
    </div>
    <div class="P-input-block">
      <ott-select-autocomplete
        v-model="filterModel.channelsMediainfo"
        @emitFunc="filterData('channelsMediainfo')"
        :height="38"
        :items="getChannelsMediaList"
        :clear="true"
        outlined
        selected-text="name"
        label="Get Channels MediaInfo"
      />
    </div>
    <div class="P-input-block">
      <ott-select-autocomplete
        v-model="filterModel.splitSources"
        @emitFunc="filterData('splitSources')"
        :height="38"
        :items="splitSourcesList"
        :clear="true"
        outlined
        selected-text="name"
        label="Split Sources"
      />
    </div>
    <div class="P-input-block">
      <ott-select-autocomplete
        v-model="filterModel.isEdge"
        @emitFunc="filterData('isEdge')"
        :height="38"
        :items="isEdgeList"
        :clear="true"
        outlined
        selected-text="name"
        label="is Edge"
      />
    </div>
    <div class="P-input-block">
      <ott-select-autocomplete
        v-model="filterModel.hostOnlineStatus"
        @emitFunc="filterData('hostOnlineStatus')"
        :height="38"
        :items="hostOnlineStatusList"
        :clear="true"
        outlined
        selected-text="name"
        label="Host Online Status"
      />
    </div>
    <div class="P-input-block">
      <ott-select-autocomplete
        v-model="filterModel.flussonicOnlineStatus"
        @emitFunc="filterData('flussonicOnlineStatus')"
        :height="38"
        :items="flussonicOnlineStatusList"
        :clear="true"
        outlined
        selected-text="name"
        label="Flussonic Online Status"
      />
    </div>

    <selected-filters-list v-if="filterModel.filterCount.length" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
import SelectedFiltersList from "./SelectedFiltersList";
export default {
  name: "FilterData",
  components: { OttInput, SelectedFiltersList, OttSelectAutocomplete },
  computed: {
    ...mapState({
      filterModel: (state) => state.flussonicsModule.filterModel,
      filter: (state) => state.flussonicsModule.filterData,
      enableList: (state) => state.flussonicsModule.enableList,
      collectStatList: (state) => state.flussonicsModule.collectStatList,
      remoteUpdateList: (state) => state.flussonicsModule.remoteUpdateList,
      getChannelsMediaList: (state) => state.flussonicsModule.getChannelsMediaList,
      splitSourcesList: (state) => state.flussonicsModule.splitSourcesList,
      isEdgeList: (state) => state.flussonicsModule.isEdgeList,
      hostOnlineStatusList: (state) => state.flussonicsModule.hostOnlineStatusList,
      flussonicOnlineStatusList: (state) => state.flussonicsModule.flussonicOnlineStatusList,
    }),
  },

  methods: {
    ...mapActions({
      getFlussonicsList: "flussonicsModule/getFlussonicsList",
    }),

    ...mapMutations({
      setFilterPage: "flussonicsModule/setFilterPage",
      sortColumn: "flussonicsModule/sortColumn",
      filterFlussonics: "flussonicsModule/filterFlussonics"
    }),

    async filterData(type) {
      this.setFilterPage(1)
      this.filterFlussonics()
      switch (type) {
        case 'search':
          if (!this.filterModel.search) {
            this.filterModel.resetCurrentData(type)
          }
          break;
      
        case 'enabled':
          if (!this.filterModel.enabled) {
            this.filterModel.resetCurrentData(type)
          }
          break;
      
        case 'collectStat':
          if (!this.filterModel.collectStat) {
            this.filterModel.resetCurrentData(type)
          }
          break;
      
        case 'remoteUpdate':
          if (!this.filterModel.remoteUpdate) {
            this.filterModel.resetCurrentData(type)
          }
          break;
      
        case 'channelsMediainfo':
          if (!this.filterModel.channelsMediainfo) {
            this.filterModel.resetCurrentData(type)
          }
          break;
      
        case 'splitSources':
          if (!this.filterModel.splitSources) {
            this.filterModel.resetCurrentData(type)
          }
          break;
      
        case 'isEdge':
          if (!this.filterModel.isEdge) {
            this.filterModel.resetCurrentData(type)
          }
          break;
      
        case 'hostOnlineStatus':
          if (!this.filterModel.hostOnlineStatus) {
            this.filterModel.resetCurrentData(type)
          }
          break;
      
        case 'flussonicOnlineStatus':
          if (!this.filterModel.flussonicOnlineStatus) {
            this.filterModel.resetCurrentData(type)
          }
          break;
      }

      this.filterModel.getFilterCount();
      this.sortColumn();
      await this.getFlussonicsList(this.filter);
      this.$emit('resetSelectedList')
    },

    async resetData(type) {
      this.filterModel.resetCurrentData(type)
      this.filterFlussonics()
      this.filterModel.getFilterCount()
      await this.getFlussonicsList(this.filter)
      this.$emit('resetSelectedList')
    }
  },
};
</script>

<style scoped>

.P-input-block {
  margin-bottom: 20px;
}
</style>
