<template>
  <ott-dialog
    :is-open="isOpenManageFlussonicModal"
    :show-footer="false"
    :modal-title="'Add Flussonic'"
    :width="990"
    @outside="closeModal"
    @resetDataModal="closeModal"
  >
    <template v-slot:modalBody>
      <div class="parent-add-provider-modal">
        <div class="left P-background-neutral7 G-flex-column G-justify-between">
          <div>
            <ul class="list">
              <li
                v-for="(tab, index) of modalTabs"
                @click="clickList(index + 1)"
                :key="index"
                :class="{
                  listActive: index + 1 === activeTab,
                }"
              >
                {{ tab.name }}
              </li>
            </ul>
            <ott-button-loader
              v-if="!flussonicId"
              className="primary-sm-btn"
              button-text="Save and Close"
              @onClick="saveAndClose"
            />
          </div>
        </div>
        <div class="right">
          <div class="main">
            <General v-if="activeTab === 1" ref="flussonicGeneral" />
            <Ports v-if="activeTab === 2" ref="flussonicPorts" />
            <DvrVod v-if="activeTab === 3" ref="flussonicDvrVod" />
            <CustomConfigs v-if="activeTab === 4" ref="customConfigs"/>

            <Transition name="fade">
              <v-alert
                v-if="isSaved"
                dense
                text
                class="mt-5"
                type="success"
                style="width: max-content"
              >
                The request has been saved successfully
              </v-alert>
            </Transition>
          </div>
          <div class="footer" v-if="!flussonicId">
            <ott-button-loader
              :is-loading="isLoading"
              className="primary-sm-btn"
              button-text="Save and Continue"
              icon-after
              @onClick="nextFunc"
            />
          </div>
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
// components
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import General from "./tabs/General";
import Ports from "./tabs/Ports";
import DvrVod from "./tabs/DvrVod";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import CustomConfigs from './tabs/CustomConfigs.vue';
export default {
  name: "ManageFlussonic",
  components: {OttButtonLoader, General, OttDialog, Ports, DvrVod, CustomConfigs },
  computed: {
    ...mapState({
      activeTab: state => state.flussonicsModule.activeTab,
      isOpenManageFlussonicModal: state =>
        state.flussonicsModule.isOpenManageFlussonicModal,
      modalTabs: state => state.flussonicsModule.modalTabs,
      flussonicId: state => state.flussonicsModule.flussonicId,
      isSaved: state => state.appModule.isSaved,
      isLoading: state => state.appModule.isLoading
    }),
  },
  methods: {
    ...mapMutations({
      setData: "flussonicsModule/setData",
      generalTabReset: "generalFlussonic/resetState",
      portsTabReset: "portsFlussonic/resetState",
      dvrVodTabReset: "dvrVodFlussonic/resetState",
    }),
    closeModal() {
      this.setData({ isOpenManageFlussonicModal: false, activeTab: 1 });
      this.generalTabReset()
      this.portsTabReset()
      this.dvrVodTabReset()
    },

    clickList(activeTab) {
      this.setData({ activeTab });
    },
    async nextFunc() {
      switch (this.activeTab) {
        case 1: {
          this.$refs['flussonicGeneral'].saveData().then(data => {
            if (data) {
              this.setData({ activeTab: 2 })
            }
          })
          break
        }
        case 2: {
          this.$refs['flussonicPorts'].saveData().then(data => {
            if (data) {
              this.setData({ activeTab: 3 })
            }
          })
          break
        }
        case 3: {
          this.$refs['flussonicDvrVod'].saveData().then(data => {
            if (data) {
              this.setData({ activeTab: 4 })
            }
          })
          break
        }
      }
    },
    async saveAndClose() {}
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.parent-add-provider-modal {
  display: flex;

  .left {
    min-width: 226px;
    padding: 18px 14px 24px;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .list {
      padding-left: 0;
      overflow-y: scroll;
      height: 650px;
      text-transform: capitalize;

      li {
        padding: 7px 11px;
        cursor: pointer;
        border-radius: 4px;
        font: normal normal 600 14px Segoe UI;
        white-space: nowrap;
        transition: $transition;
      }
    }
  }

  .right {
    padding: 14px 24px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #a5aac6;
      margin: 24px -24px 0 -24px;
      padding: 24px 24px 0;
    }
    ::v-deep {
      .P-modal-component-header {
        margin-bottom: 0;
      }
    }
  }
}

</style>