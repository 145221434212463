<template>
  <div class="draggable-select">
    <div class="draggable-select-wrapper">
      <v-sheet :elevation="6" class="" rounded shaped>
        <div class="draggable-items">
          <draggable
            :dragData="dragData.values"
            :disabled="!enabled"
            :animation="0"
            class="list-group"
            ghost-class="ghost"
            group="description"
            @start="dragging = true"
            @end="dragged"
          >
            <transition-group type="transition" name="list">
              <v-chip
                v-for="drag in dragData.values"
                :key="drag.id"
                class="m-10"
                close
                @click:close="$emit('removeDraggable', drag.id)"
              >
                {{ drag.name }}
              </v-chip>
            </transition-group>
          </draggable>

          <div class="field">
            <ott-input
              type="text"
              :error="error"
              :value="dragData.dragValue"
              @onChange="$emit('onChange')"
              @input="$emit('onInput', { value: $event, dragData })"
              :label="label"
            >
            </ott-input>
          </div>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import OttInput from "@/components/vuetifyComponents/OttInput";
export default {
  components: {
    draggable,
    OttInput,
  },
  data() {
    return {
      dragging: false,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    error: {
      type: Boolean,
      default: false,
    },
    dragData: {
      type: Object,
      default: () => {},
    },
    enabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
  },
  methods: {
    dragged() {
      this.dragging = false;
      this.$emit("onMove");
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-select {
  &-wrapper {
    .draggable-items {
      max-width: 100%;
      border: 1px solid #a5aac6;
      border-radius: 4px;

      .item {
        padding: 0 20px;
        color: #fff;
        // margin: 5px;
        border-radius: 15px;
        cursor: pointer;
      }
    }
  }
}
.flip-list-move {
  transition: all 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  padding-left: 18px;
  padding-right: 18px;
  span {
    display: flex;
    flex-wrap: wrap;
  }
}

.v-chip {
  margin-left: 0 !important;
}

.v-text-field {
  margin-top: 10px;
  padding: 0;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.list-leave-active {
  position: absolute;
}

.v-size--default {
  border-radius: 4px !important;
}

.m-10 {
  margin: 10px;
}

</style>

<style lang="scss">
.v-input__slot {
  margin-bottom: 0 !important;
}
.draggable-select {
  & fieldset {
    border: unset !important;
  }

  & .v-input__control {
    margin: -1px;
    width: 100.4%;
  }

  & .v-label--active {
    background: white !important;
  }

  // .v-text-field--outlined.v-input--has-state fieldset,
  // .v-text-field--outlined.v-input--is-focused fieldset {
  //   border: unset!important;
  // }

  // .v-input--dense .v-label {
  //   display: none !important;
  // }
}
</style>