<template>
  <selected-filters 
    :selected-list="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";

export default {
  components: { SelectedFilters },

  computed: {
    ...mapState({
      filterModel: (state) => state.flussonicsModule.filterModel,
      filter: (state) => state.flussonicsModule.filterData,
    }),

    selectedList() {
      return [
        {
          ifCondition: !!this.filterModel.search,
          boxText: '<b>Search</b>: ' + this.filterModel.search,
          resetArgument: "search",
        },
        {
          ifCondition:
            !!this.filterModel.enabled || this.filterModel.enabled === 0,
          boxText: `<b>Enabled</b>: ${this.getBoxText({
            type: "enable",
            payload: this.filterModel.enabled,
          })}`,
          resetArgument: "enabled",
        },
        {
          ifCondition:
            !!this.filterModel.collectStat ||
            this.filterModel.collectStat === 0,
          boxText: `<b>Collect Stat</b>: ${this.getBoxText({
            type: "enable",
            payload: this.filterModel.collectStat,
          })}`,
          resetArgument: "collectStat",
        },
        {
          ifCondition:
            !!this.filterModel.remoteUpdate ||
            this.filterModel.remoteUpdate === 0,
          boxText: `<b>Remote Update</b>: ${this.getBoxText({
            type: "enable",
            payload: this.filterModel.remoteUpdate,
          })}`,
          resetArgument: "remoteUpdate",
        },
        {
          ifCondition:
            !!this.filterModel.channelsMediainfo ||
            this.filterModel.channelsMediainfo === 0,
          boxText: `<b>Get Channels Media</b>: ${this.getBoxText({
            type: "enable",
            payload: this.filterModel.channelsMediainfo,
          })}`,
          resetArgument: "channelsMediainfo",
        },
        {
          ifCondition:
            !!this.filterModel.splitSources ||
            this.filterModel.splitSources === 0,
          boxText: `<b>Split Sources</b>: ${this.getBoxText({
            type: "enable",
            payload: this.filterModel.splitSources,
          })}`,
          resetArgument: "splitSources",
        },
        {
          ifCondition:
            !!this.filterModel.isEdge || this.filterModel.isEdge === 0,
          boxText: `<b>Is Edge</b>: ${this.getBoxText({
            type: "enable",
            payload: this.filterModel.isEdge,
          })}`,
          resetArgument: "isEdge",
        },
        {
          ifCondition:
            !!this.filterModel.hostOnlineStatus ||
            this.filterModel.hostOnlineStatus === 0,
          boxText: `<b>Host Online Status</b>: ${this.getBoxText({
            type: "online",
            payload: this.filterModel.hostOnlineStatus,
          })}`,
          resetArgument: "hostOnlineStatus",
        },
        {
          ifCondition:
            !!this.filterModel.flussonicOnlineStatus ||
            this.filterModel.flussonicOnlineStatus === 0,
          boxText: `<b>Flussonic Online Status</b>: ${this.getBoxText({
            type: "online",
            payload: this.filterModel.flussonicOnlineStatus,
          })}`,
          resetArgument: "flussonicOnlineStatus",
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      getFlussonicsList: "flussonicsModule/getFlussonicsList",
    }),

    ...mapMutations({
      filterFlussonics: "flussonicsModule/filterFlussonics",
    }),

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterFlussonics()
      await this.getFlussonicsList(this.filter);
    },
    getBoxText({ type, payload }) {
      let result = "";

      if (type === "enable") {
        result =
          payload === 1
            ? "Select All"
            : payload === 2
            ? "Enable"
            : payload === 3
            ? "Disable"
            : null;
      }
      if (type === "online") {
        result =
          payload === 1
            ? "Select All"
            : payload === 2
            ? "Online"
            : payload === 3
            ? "Offline"
            : null;
      }

      return result;
    },
  },
};
</script>
